
export const throttle = (callback, time) => {
  let throttlePause;

  return () => {
    if (throttlePause) return;
    throttlePause = true;
    
    callback();

    setTimeout(() => {
      throttlePause = false;
    }, time);
  }
};