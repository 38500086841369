import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { TGame } from "./types/Game";
import { FEATURE_FLAGS, isFeatureEnable } from "./helpers/featureFlags";
import { getAccountByEmail } from "./helpers/account";

const functions = getFunctions();
if (import.meta.env.MODE === 'local') {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

const apiRequest = (functionName: string, data: any, email?: string) => { // eslint-disable-line @typescript-eslint/no-explicit-any
  console.log("apiRequest data", data)
  if (!isFeatureEnable(FEATURE_FLAGS.NOTIFICATIONS, email)) {
    console.log(`notifications disabled apiRequest ${functionName} cancelled`);
    return;
  }

  const callable = httpsCallable(functions, functionName, { limitedUseAppCheckTokens: true });
  callable(data).then(async (response) => {
    console.log("response", response);
  }).catch(error => {
    console.error(`Failed call to ${functionName}`);
    console.error("error", error)
    const { code, message, details }  = error;
    console.error(`code ${code} message ${message} details ${details}`);
  });
}

export const notifyPlayerRemoved = async (email: string|undefined, gameId: string) => {
  if (!email) {
    return;
  }

  const account = await getAccountByEmail(email);
  if (!account?.notificationsToken) {
    console.log(`No notifications token set for ${email}`)
    return;
  }

  apiRequest("notifyPlayerRemoved", {
    token: account.notificationsToken,
    gameId,
  }, email);
}

export const notifyPlayerIn = async (email: string|undefined, gameId: string) => {
  if (!email) {
    return;
  }

  const account = await getAccountByEmail(email);
  if (!account?.notificationsToken) {
    console.log(`No notifications token set for ${email}`)
    return;
  }

  apiRequest("notifyPlayerIn", {
    token: account.notificationsToken,
    gameId,
  }, email);
}

export const notifyOwnerPlayersReady = async (game: TGame) => {
  if (!game.owner.email) {
    return;
  }

  const account = await getAccountByEmail(game.owner.email);
  if (!account?.notificationsToken) {
    console.log(`No notifications token set for ${game.owner.email}`)
    return;
  }

  apiRequest("notifyOwnerPlayersReady", {
    token: account.notificationsToken,
    gameId: game.id,
  }, game.owner.email);
}

export const notifyOwnerPlayersMissing = async (game: TGame) => {
  if (!game.owner.email) {
    return;
  }

  const account = await getAccountByEmail(game.owner.email);
  if (!account?.notificationsToken) {
    console.log(`No notifications token set for ${game.owner.email}`)
    return;
  }

  apiRequest("notifyOwnerPlayersMissing", {
    token: account.notificationsToken,
    gameId: game.id,
  }, game.owner.email);
}

export default {
  notifyPlayerRemoved,
  notifyPlayerIn,
  notifyOwnerPlayersReady,
  notifyOwnerPlayersMissing,
}