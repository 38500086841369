import { useState, useEffect, useMemo } from 'react';
import { getFirestore, doc, query, where, collection, updateDoc, onSnapshot, arrayUnion, and, or } from "firebase/firestore";
import { Link, RouteComponentProps } from 'react-router-dom';
import { startOfDay } from "date-fns/startOfDay";
import { subWeeks } from "date-fns/subWeeks";
import { UserContextProps, withUserContext } from './../../contexts/user';
import GamesList from '../common/GamesList/GamesList';
import { isUnitedPadelHostname, UNITED_PADEL_ID } from "../../helpers/communities";
import { TGame, VISIBILITY } from '../../types/Game';
import { useIsBooker } from '../../hooks/isBooker';
import { getLogEntry } from '../../helpers/logs';
import { bookersLookahead, communityMembersMaxLookahead, isPastGame } from '../../helpers/game';
import { useDocumentVisibility } from '../../hooks/useDocumentVisibility';

type CommunityProps = UserContextProps & RouteComponentProps<{ id: string }>

const Community = (props: CommunityProps) => {
    const [allGames, setAllGames] = useState<TGame[]>([]);
    const [pastWeekGames, setPastWeekGames] = useState<TGame[]>([]);
    const [communityGames, setCommunityGames] = useState<TGame[]>([]);
    const [deletedGames, setDeletedGames] = useState<TGame[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const { user } = props.userContext;

    const communityId = useMemo(() => {
        if (!!props.match.params.id) {
            return props.match.params.id
        }

        if(isUnitedPadelHostname()) {
            return UNITED_PADEL_ID;
        }
    }, [props.match.params.id])

    const isBooker = useIsBooker({ user, communityId });

    const updateGames = (games: TGame[]) => {
        setDeletedGames(games.filter(g => g.isDeleted));
            
        const availableGames = games.filter(game => !game.isDeleted && (game.visibility !== VISIBILITY.NOT_VISIBLE || isBooker));

        const pastGames = availableGames.filter(game => isPastGame(game)).reverse();
        setPastWeekGames(pastGames);

        const futureGames = availableGames.filter(game => !isPastGame(game));
        setCommunityGames(futureGames);
        setIsLoading(false);
    }

    // If games don't change but time goes by we might show games that have been
    // already finished still in upcomingGames. To fix this whenever visibility
    // changes we check if the very first game is already past, and then
    // update all our games lists
    const isDocumentVisible = useDocumentVisibility();
    useEffect(() => {
        if (!isDocumentVisible) {
            return;
        }

        const nextGame = communityGames[0];
        if (!nextGame) {
            return;
        }

        const shouldUpdateGames = isPastGame(nextGame);
        if(shouldUpdateGames) {
            updateGames(allGames);
        }
    }, [isDocumentVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateGames(allGames);
    }, [allGames]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!user) {
            return;
        }

        const unsubscribe = watchCommunityGames();
        return () => {
            if (!!unsubscribe) {
                unsubscribe();
            }
        }
    }, [user, communityId, isBooker]); // eslint-disable-line react-hooks/exhaustive-deps

    const watchCommunityGames = () => {
        if (!communityId) {
            setIsLoading(false);
            return;
        }

        const startOfLastWeek = startOfDay(subWeeks(new Date(), 1));
        const q = query(collection(getFirestore(), "games"),
            and(
                where("community", "==", communityId),
                where("time", ">=", startOfLastWeek),
                or(
                    where("time", "<=", isBooker ? bookersLookahead : communityMembersMaxLookahead),
                    where("alwaysVisible", "==", true),
                    where("visibility", "==", VISIBILITY.ALWAYS_VISIBLE),
                )
            )
        )

        return onSnapshot(q, querySnapshot => {
            if (querySnapshot.empty) {
                if (isLoading) {
                    setIsLoading(false);
                }
                return;
            }

            const games: TGame[] = [];
            querySnapshot.forEach(doc => {
                const game = doc.data();
                games.push({
                    ...game,
                    id: doc.ref.id,
                } as TGame);
            });

            setAllGames(games);
        });
    }

    const deleteGame = (game: TGame) => {
        if (!confirm(`Are you sure you want to delete ${game.name}?`)) {
            return;
        }

        updateDoc(doc(getFirestore(), "games", game.id), {
            isDeleted: true,
            events: arrayUnion(getLogEntry("Game deleted", user?.displayName)),
        }).catch(function (error) {
            console.error("Error removing document: ", error);
        });
    }

    return (
        <div className="col col-lg-9 col-xl-8 col-xxl-7">
            <GamesList title="Upcoming games" games={communityGames} isLoading={isLoading} handleDelete={deleteGame} userUID={user?.uid} isBooker={isBooker} />
            {(!!pastWeekGames?.length || isBooker)  && <GamesList title="Past games" games={pastWeekGames} isLoading={isLoading} disabled={true} collapsed={true} />}
            {isBooker && <GamesList title="Deleted games" games={deletedGames} isLoading={isLoading} disabled={true} collapsed={true} />}
            {!!user && <Link to={`/game/new?community=${communityId}`} className="btn btn-primary">Create game</Link>}
        </div>
    );
}

export default withUserContext(Community);