import { useState } from 'react';
import PlayerFormModal from './PlayerFormModal';
import { CAN_ADD_VALUES } from '../../variables';
import { UserContextProps, withUserContext } from '../../contexts/user';
import { TGame } from '../../types/Game';
import { Player } from '../../types/Player';
import { isUserGameOwner } from '../../helpers/game';
import { useIsBooker } from '../../hooks/isBooker';

type InviteSectionProps = {
    game: TGame
    onPlayerAdded: (player: Player) => void
} & UserContextProps

const InviteSection = ({ game, userContext, onPlayerAdded }: InviteSectionProps) => {
    const [isAddingPlayer, setIsAddingPlayer] = useState(false);
    const { user } = userContext;
    const isBooker = useIsBooker({ user, communityId: game?.community });

    const toggleAddingPlayer = () => {
        setIsAddingPlayer(!isAddingPlayer);
    }

    const handleSubmit = (player: Player) => {
        setIsAddingPlayer(false);
        onPlayerAdded(player);
    }

    const canAdd = game.canAdd === CAN_ADD_VALUES.ANYONE ||
        (game.canAdd === CAN_ADD_VALUES.BOOKERS && isBooker) ||
        isUserGameOwner(game, user?.uid);

    return (
        <>
            {isAddingPlayer && (
                <PlayerFormModal onSubmit={handleSubmit} onClose={() => setIsAddingPlayer(false)} />
            )}
            {canAdd && (
                <button className="btn btn-secondary" onClick={toggleAddingPlayer}>
                    <i className="fas fa-plus-circle"></i>
                    <span className="d-none d-md-inline ms-2">Add a guest</span>
                    <span className="d-md-none ms-2">Guest</span>
                </button>
            )}
        </>
    )
}

export default withUserContext(InviteSection);