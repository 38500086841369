import { useState, useEffect, useMemo, ButtonHTMLAttributes } from 'react';
import { getFirestore, collection, onSnapshot, doc, setDoc, arrayUnion, DocumentReference, arrayRemove } from "firebase/firestore";
import cn from "classnames";
import { Link, RouteComponentProps } from 'react-router-dom';
import { format } from 'date-fns/format';
import Accordion from "react-bootstrap/Accordion";
import { Alert, Button, ButtonGroup, Dropdown, DropdownButton, Toast, ToastContainer } from "react-bootstrap";
import { UserContextProps, withUserContext } from './../../contexts/user';
import InviteSection from './InviteSection';
import { getLogEntry } from "../../helpers/logs";
import './Game.css';
import { TGame } from '../../types/Game';
import { Unsubscribe } from 'firebase/auth';
import { Player } from '../../types/Player';
import { getCalendarHref, isPastGame, isUserGameOwner } from '../../helpers/game';
import { useIsBooker } from '../../hooks/isBooker';
import api, { notifyOwnerPlayersReady } from './../../api';
import { FEATURE_FLAGS, isFeatureEnable } from '../../helpers/featureFlags';
import { RANKING } from '../../types/Account';

type GameProps = UserContextProps & RouteComponentProps<{ id: string }>

const GameComponent = (props: GameProps) => {
    const [game, setGame] = useState<TGame>();
    const [gameRef, setGameRef] = useState<DocumentReference>();
    const [loading, setLoading] = useState(true);
    const [listeners, setListeners] = useState<Unsubscribe[]>([]);
    const [showToastJoined, setShowToastJoined] = useState(false);
    const [showToastShared, setShowToastShared] = useState(false);

    const { match } = props;
    const { account, user } = props.userContext;
    const gameId = match.params.id;

    useEffect(() => {
        return () => {
            if (listeners) {
                listeners.forEach(listener => listener());
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!gameId) {
            return;
        }
        const db = getFirestore();
        const gamesRef = collection(db, "games");
        const gameRef = doc(gamesRef, gameId);
        setGameRef(gameRef);
        const listener = onSnapshot(gameRef, doc => {
            if (doc.exists()) {
                const game = doc.data() as TGame;
                setGame(game);
            }
            setLoading(false)
        });
        setListeners([listener]);
    }, [gameId]);

    const isBooker = useIsBooker({ user, communityId: game?.community });
    const isOwner = isUserGameOwner(game, user?.uid);
    const isGameOver = useMemo(() => !game || isPastGame(game), [game]);

    const isExcludedByRank = useMemo(() => {
        if (!game || !user || !isFeatureEnable(FEATURE_FLAGS.RANKING, user?.email)) {
            return false;
        }

        if (!!game.ranking && (account?.ranking || []).every(rank => rank !== game.ranking)) {
            return true;
        }
    }, [user, game, account]);

    const isGameDisabled = useMemo(() => {
        if (!game || game.isDeleted || isGameOver) {
            return true;
        }
        return false;
    }, [game, isGameOver]);

    const eventsReversed = useMemo(() => (game?.events || []).slice().reverse(), [game?.events]);

    const getInviteLink = () => {
        if (!user) {
            return;
        }
        const inviteQueryParam = `?i=${user.uid}`;
        return window.location.href + inviteQueryParam;
    }

    const handleJoin = async () => {
        if (!game || !user) {
            return;
        }
        const userUID = getUserUIDFromQueryParams();
        const invitedBy = getUserNameByUID(userUID);

        const player: Player = {
            name: user.displayName || "",
            photo: user.photoURL || "",
            email: user.email || "",
            invitedByName: invitedBy?.name || "",
            uid: user.uid,
        };

        const logActionBase = `${player.name} joined`;
        const events = arrayUnion(getLogEntry(invitedBy?.name ? `${logActionBase} invited by ${invitedBy.name}` : logActionBase));

        return setDoc(gameRef!, {
            events,
            players: arrayUnion(player),
            playersNames: arrayUnion(player.name),
        }, { merge: true })
            .then(() => {
                const updatedPlayersLength = game.players.length + 1;
                if (!isOwner && updatedPlayersLength === game.playersNeeded) {
                    notifyOwnerPlayersReady({
                        ...game,
                        id: gameId
                    });
                }
                if (updatedPlayersLength > game!.playersNeeded) {
                    setShowToastJoined(true);
                }
            })
            .catch(console.error)
    }

    const leaveGame = () => {
        if (!confirm(`Are you sure you want to leave? even if you join again you might lose your place`)) {
            return;
        }

        const player = game!.players.find(player => user!.displayName === player.name);
        if (!player) {
            return;
        }
        return onPlayerRemove(player, false);
    }

    const getUserUIDFromQueryParams = () => new URLSearchParams(props.location.search).get('i');

    const getUserNameByUID = (uid: string|null) => {
        if (!uid || !game) {
            return null;
        }
        if (game.owner.uid === uid) {
            return game.owner;
        }
        return game.players.find(player => player.uid === uid);
    }

    const onPlayerAdded = (player: Player) => {
        if (!user) {
            return;
        }
        const events = arrayUnion(getLogEntry(`${player.name} added`, user.displayName));
        return setDoc(gameRef!, {
            players: arrayUnion(player),
            playersNames: arrayUnion(player.name),
            events,
        }, { merge: true })
            .catch(console.error)
    }

    const getSubPlayerIfNeeded = (playerRemoved: Player): Player|undefined => {
        if (!game) {
            return;
        }

        const index = game.players.findIndex(p => p === playerRemoved);
        const position = index + 1;
        const removedPlayerWasNotNeeded = position > game.playersNeeded;
        if (removedPlayerWasNotNeeded) {
            return;
        }
        
        const subPlayer = game.players[game.playersNeeded];
        return subPlayer;
    }

    const onPlayerRemove = (playerRemoved: Player, askConfirmation = true) => {
        if (!game || !user) {
            return;
        }

        if (askConfirmation && !confirm(`Are you sure you want to remove ${playerRemoved.name}?`)) {
            return;
        }

        const subPlayer = getSubPlayerIfNeeded(playerRemoved);
        const playerWasRemoved = askConfirmation;
        const logAction = (playerWasRemoved) ? `removed by ${user.displayName}` : "left";
        return setDoc(gameRef!, {
            players: arrayRemove(playerRemoved),
            playersNames: arrayRemove(playerRemoved.name),
            events: arrayUnion(getLogEntry(`${playerRemoved.name} ${logAction}`)),
        }, { merge: true })
            .then(() => {
                if (playerWasRemoved && !!playerRemoved.email) {
                    api.notifyPlayerRemoved(playerRemoved.email, gameId);
                }
                if (subPlayer?.email) {
                    api.notifyPlayerIn(subPlayer.email, gameId);
                }

                const updatedPlayersLength = game.players.length - 1;
                const gameIsOnePlayerShort = updatedPlayersLength === game.playersNeeded - 1;
                if (!isOwner && gameIsOnePlayerShort) {
                    api.notifyOwnerPlayersMissing({
                        ...game,
                        id: gameId
                    });
                }
            })
            .catch(console.error)
    }

    const handleShare = () => {
        if (!game) {
            return;
        }

        const prettyDate = format(game.time?.toDate(), "HH:mm 'on' EEEE dd 'of' MMMM");
        if (!!navigator.share) {
            navigator.share({
                title: `United Padel NZ ${game.name}`,
                text: `We're playing at ${prettyDate}. Join us!`,
                url: getInviteLink(),
            }).catch((error) => console.error('Error sharing', error));
        } else {
            navigator.clipboard.writeText(`We're playing at ${prettyDate}. Join us! ${getInviteLink()}`);
            setShowToastShared(true);
        }
    }

    const getPlayerInfoLabel = (player: Player, game: TGame) => {
        if (player.email === game.owner.email) {
            return '(organiser)';
        }

        if (player.invitedByName) {
            return `(invited by ${player.invitedByName})`
        }

        return '';
    }

    if (loading) {
        return (
            <p className="fs-5">
                <i className="fas fa-spinner spin mb-3"></i>
                <span className="ms-3">
                    Loading game information.
                </span>
            </p>
        );
    }

    if (!game) {
        return (
            <>
                <h5>Game not found.</h5>
                <p>Click <Link to="/">here</Link> to see a list of games you can access.</p>
            </>
        )
    }

    const userJoined = user && game.players.find(player => player.email === user.email);

    const renderJoinButton = () => {
        const buttonClasses = "btn d-flex align-items-center justify-content-center";
        const buttonProps: Partial<ButtonHTMLAttributes<HTMLButtonElement>> = {
            type: "button",
            onClick: handleJoin,
        };

        if (game.players.length < game.playersNeeded) {
            return (
                <button className={`${buttonClasses} btn-primary`} {...buttonProps}>
                    <i className="bi bi-person-check-fill fs-5 me-2"></i> Join game
                </button>
            )
        }
        return (
            <button className={`${buttonClasses} btn-warning`} {...buttonProps}>
                <i className="bi bi-clock me-2"></i> Join waiting list
            </button>
        )
    }

    const canShare = !!navigator.share || !!navigator.clipboard?.writeText;

    return (
        <div className="game col col-lg-9 col-xl-8 col-xxl-7">
            <header className="d-flex flex-row align-items-center mb-3">
                <h3 className="mb-0">{game.name}</h3>
                <div className="game-toolbar ms-3">
                    {!isGameDisabled && isOwner && (
                        <Link to={`/game/${gameId}/update`} title="edit">
                            <Button variant="secondary" size="sm">
                                <i className="bi bi-pencil-square"></i>
                            </Button>
                        </Link>
                    )}
                </div>
            </header>
            <div className="fields">
                <p className="h5 mb-3">
                    {format(game.time?.toDate(), 'EEEE dd MMM HH:mm')}
                    {!!game.endTime && (<span>&nbsp; - {game.endTime}</span>)}
                </p>

                {!!game.location && (
                    <div className="mb-3">
                        At <a href={`https://www.google.com/maps/search/${encodeURI(game.location)}?hl=en&source=opensearch`}>{game.location}</a>
                    </div>
                )}

                {!!game.description && (
                    <blockquote className="blockquote mb-3">
                        <p className="card card-body field-description">
                            {game.description}
                        </p>
                    </blockquote>
                )}
            </div>
            <div className="players card position-relative mb-4">
                <div className="card-header">
                    Players
                </div>
                <ul className={cn("list-group list-group-flush", { "border-bottom-0": isGameDisabled || isExcludedByRank })}>
                    {!game.players.length ? (
                        <li className="list-group-item" key="none">
                            <p className="mb-0">None</p>
                        </li>
                    ) : (
                        game.players.map((player, i) => {
                            const position = i + 1;
                            const isPlaying = !game.playersNeeded || position <= game.playersNeeded;
                            const playerIsCurrentUser = user?.displayName === player.name;
                            const playerWasInvitedByUser = user?.displayName === player.invitedByName;
                            const showRemovePlayerButton = !isGameDisabled &&
                                !!user &&
                                !playerIsCurrentUser &&
                                (isOwner || isBooker || playerWasInvitedByUser);
                            const isRankingEnabled = isFeatureEnable(FEATURE_FLAGS.RANKING) && isGameOver && !game.isDeleted && !!user && !playerIsCurrentUser && !!player.email;

                            const photoEl = <img className="rounded-1 border border-dark-subtle" src={player.photo} alt={player.name} width="75" height="75" />;

                            return (
                                <li className={cn("list-group-item player-list-item", { 'is-playing': isPlaying, 'last': (i + 1) === game.playersNeeded })} key={player.name}>
                                    {!!game.playersNeeded && (
                                        <span className="player-position me-1">
                                            <span className="position-number">{position}.</span>
                                            {isPlaying ? (
                                                <i className="bi bi-person-check-fill fs-5"></i>
                                            ) : (
                                                <i className="bi bi-clock"></i>
                                            )}
                                        </span>
                                    )}
                                    {isRankingEnabled ? 
                                        <Link to={`/player/${player.email}`}>
                                            {photoEl}
                                        </Link>
                                    : photoEl}
                                    <div className="ms-2">
                                        <div className="player-info">
                                            {(isRankingEnabled) ? (
                                                <Link to={`/player/${player.email}`} className="me-2">{player.name}</Link>
                                            ) : (
                                                <span className="me-2">{player.name}</span>
                                            )}
                                            <small className="d-inline-block form-text text-muted">{getPlayerInfoLabel(player, game)}</small>
                                        </div>
                                        {!!game.playersNeeded && (
                                            <p className="player-status d-none d-md-block text-muted mb-0">
                                                {isPlaying ? "playing" : "waiting"}
                                            </p>
                                        )}
                                    </div>
                                    {showRemovePlayerButton && (
                                        <button title="remove" className="btn btn-outline-danger btn-sm ms-auto" onClick={() => onPlayerRemove(player)}>
                                            <i className="fas fa-user-slash"></i>
                                        </button>
                                    )}
                                </li>
                            )
                        })
                    )}
                </ul>
                {!isExcludedByRank && !isGameDisabled && !!user && (
                    <div className="card-footer">
                        {userJoined ? (
                            <button className="btn btn-danger" type="button" onClick={leaveGame}>Leave</button>
                        ) : (
                            renderJoinButton()
                        )}
                        <InviteSection game={game} onPlayerAdded={onPlayerAdded} />
                        <DropdownButton
                            as={ButtonGroup}
                            variant="secondary"
                            title="More"
                        >
                            <Dropdown.Item href={getCalendarHref(game)} target="_blank" title="Add to calendar">
                                <i className="bi bi-calendar-event-fill"></i>
                                <span className="ms-2">Add to calendar</span>
                            </Dropdown.Item>
                            {!!canShare && (
                                <>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={handleShare} title="Invite">
                                        <i className="bi bi-share"></i>
                                        <span className="ms-2">Invite</span>
                                    </Dropdown.Item>
                                </>
                            )}
                        </DropdownButton>
                    </div>
                )}
                <ToastContainer className="position-fixed" position="top-center">
                    <Toast className="d-inline-block m-2" bg="success" onClose={() => setShowToastJoined(false)} show={showToastJoined} delay={4500} autohide>
                        <Toast.Body className="text-white">
                            <p className="fs-5 mb-1">You've joined the waiting list</p>
                            <hr className="mt-2 mb-1 p-0" />
                            <p className="fs-6 mb-1">Keep an eye open for any changes</p>
                        </Toast.Body>
                    </Toast>
                    <Toast className="d-inline-block m-2" bg="success" onClose={() => setShowToastShared(false)} show={showToastShared} delay={4500} autohide>
                        <Toast.Body className="text-white">
                            <p className="fs-6 mb-1">Copied to clipboard</p>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>

            {!isExcludedByRank && !isGameDisabled && !user && (
                <p className="mb-3"><Link to="/login">Login</Link> so you can join this game</p>
            )}

            {isExcludedByRank && (
                <Alert variant="info">
                    Only for players of <strong>{RANKING[game?.ranking as keyof typeof RANKING]}</strong> rank can join this game.
                </Alert>
            )}

            {(isOwner || isBooker) && (
                <Accordion className="mt-4 mb-5">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Events history</Accordion.Header>
                        <Accordion.Body>
                            {eventsReversed.map(e => <p key={e}>{e}</p>)}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            )}
        </div>
    )
}

export default withUserContext(GameComponent);