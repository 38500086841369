import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './components/App/App.tsx'
import './index.css'
import { registerSW } from 'virtual:pwa-register'
import { throttle } from './helpers/throttle.js'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://f50532193e3cde0295db680c7738b37f@o4508311666622464.ingest.us.sentry.io/4508311668457472",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/match-bookings-test\.web\.app/, /^https:\/\/boookings\.unitedpadel\.org/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

registerSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    if (!r) {
      return;
    }

    const checkForUpdates = async () => {
      console.log("checkForUpdates");
      if (r.installing || !navigator) {
        return
      }

      if (('connection' in navigator) && !navigator.onLine) {
        return
      }

      const resp = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          'cache': 'no-store',
          'cache-control': 'no-cache',
        },
      });
      console.log("fetch response", resp?.status);

      if (resp?.status === 200) {
        console.log("updating");
        await r.update();
      }
    }

    const onVisibilityChange = throttle(checkForUpdates, 1000 * 60 * 1);

    document.addEventListener("visibilitychange", () => {
      if(document.visibilityState === "visible") {
        onVisibilityChange();
      }
    }, false);

    setInterval(() => {
      console.log(`checkForUpdates interval every ${1000 * 60 * 60 * 12} hours`);
      checkForUpdates();
    }, 1000 * 60 * 60 * 12); // every 12 hours
  },
});

// Temporary and will be deleted
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
          if (registration.active?.scriptURL.match(/firebase-messaging-sw.js/)) {
              Sentry.captureMessage(`Unregistering ${registration.active?.scriptURL}`);
              registration.unregister();
          }
      } 
  });
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
