import { useState } from 'react';
import {UserContextProps, withUserContext} from '../../contexts/user';
import { Button, Modal } from "react-bootstrap";
import { Player } from '../../types/Player';
import { getAvatarUrl } from '../../helpers/user';

type PlayerFormModalProps = {
    onClose: () => void
    onSubmit: (player: Player) => void
} & UserContextProps

const PlayerFormModal = ({ onClose, onSubmit, userContext }: PlayerFormModalProps) => {
    const [name, setName] = useState("");
    const { user } = userContext;

    const handleSubmit = () => {
        const sanitisedName = name.replace(/[^\w\s]+/ig, " ");
        const player = {
            name: sanitisedName,
            invitedByName: user!.displayName,
            photo: getAvatarUrl(name)
        } as Player
        onSubmit(player);
    }

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a player</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label className="sr-only" htmlFor="name">Name</label>
                <input className="form-control me-2 mb-2" type="text" onChange={(e) => setName(e.target.value)} placeholder="Name" required />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary" type="button" onClick={handleSubmit}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default withUserContext(PlayerFormModal);