import { Link } from 'react-router-dom';
import cn from "classnames";
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { format } from "date-fns/format";
import { isAfter } from 'date-fns/isAfter';
import { subDays } from 'date-fns/subDays';
import "./GamesList.css";
import { TGame, VISIBILITY } from '../../../types/Game';
import { RANKING } from '../../../types/Account';
import { COMMUNITY_MEMBERS_MAX_LOOKAHEAD_DAYS, communityMembersMaxLookahead, isUserGameOwner } from '../../../helpers/game';
import { FEATURE_FLAGS, isFeatureEnable } from '../../../helpers/featureFlags';
import { UserContextProps, withUserContext } from '../../../contexts/user';

const gameDateOrDefault = (game: TGame) => game.time ? game.time.toDate() : new Date()

type GamesListProps = {
  title: string
  isLoading: boolean
  games: TGame[]
  collapsed?: boolean;
  handleDelete?: (game: TGame) => void
  userUID?: string
  disabled?: boolean
  isBooker?: boolean
} & UserContextProps;

const GamesList = ({
  title,
  isLoading,
  games,
  collapsed = false,
  handleDelete,
  userUID,
  disabled,
  isBooker,
  userContext,
}: GamesListProps) => {
  const { user } = userContext;

  if (isLoading) {
    return (
      <p className="fs-5">
        <i className="fas fa-spinner spin mb-3"></i>
        <span className="ms-3">
          Loading {title}.
        </span>
      </p>
    );
  }

  const renderGameTime = (game: TGame) => {
    const gameDate = gameDateOrDefault(game);
    const notVisibleYet = !!game?.community &&
      (
        !!game.visibility ? game.visibility === VISIBILITY.NORMAL_LOOKAHEAD : !game.alwaysVisible
      ) &&
      isAfter(gameDate, communityMembersMaxLookahead);
    return (
      <span>
        {format(gameDate, 'EEE dd MMM HH:mm')}
        {notVisibleYet && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Game will be visible to members on {format(subDays(gameDate, COMMUNITY_MEMBERS_MAX_LOOKAHEAD_DAYS), 'dd MMM')}</Tooltip>}
          >
            <i className="icon-not-yet-visible bi bi-hourglass-split ms-2"></i>
          </OverlayTrigger>
        )}
        {game.visibility === VISIBILITY.NOT_VISIBLE && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Game set to not visible. Edit to change it</Tooltip>}
          >
            <i className="icon-not-yet-visible bi bi-eye-slash-fill ms-2"></i>
          </OverlayTrigger>
        )}
      </span>
    )
  }

  const renderRanking = (game: TGame) => (
    <span className="text-secondary">
      {!!game.ranking ? RANKING[game.ranking! as keyof typeof RANKING] : "Any rank"}
    </span>
  );

  const itemKey = "0";
  const activeKey = collapsed ? null : [itemKey];
  return (
    <Accordion className={cn("games-list card mb-3", { "disabled": disabled })} defaultActiveKey={activeKey} alwaysOpen>
      <Accordion.Item eventKey={itemKey}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body className="p-0">
          <ul className="list-group list-group-flush">
            {!games.length ? (
              <li className="list-group-item" key={title}>
                <p className="mb-0">None</p>
              </li>
            ) : (
              games.map(game => {
                const canDelete = !!handleDelete && (isBooker || isUserGameOwner(game, userUID));
                return (
                  <li className="game-item list-group-item d-flex align-items-center row-gap-3" key={game.id}>
                    <div className="game-details d-flex flex-column">
                      <Link className="text-truncate fs-5 text-decoration-none" to={`/game/${game.id}`}>
                        {game.name}
                      </Link>
                      <div className="d-flex flex-column align-items-start flex-sm-row align-items-sm-center">
                        {renderGameTime(game)}
                        {isFeatureEnable(FEATURE_FLAGS.RANKING, user?.email) && (
                          <>
                            <span className="d-none d-sm-block mx-1">{" - "}</span>
                            {renderRanking(game)}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="players-total d-flex align-items-center">
                      <i className="bi bi-people-fill fs-5 me-2"></i>
                      <span className="total-number">
                        {game.players.length}
                        <span className="bar">/</span>
                        {game.playersNeeded}
                      </span>
                    </div>
                    <div className="players-images d-none d-md-flex align-items-center">
                      {game.players.slice(0, Math.min(game.playersNeeded, 7)).map(player => (
                        <div key={player.name} className="player-image-container-overlap">
                          <img className="player-image rounded-circle border border-dark-subtle" src={player.photo} />
                        </div>
                      ))}
                    </div>
                    <button
                      className={cn("delete-game btn btn-danger btn-sm ms-auto", { "invisible": !canDelete })}
                      onClick={() => handleDelete ? handleDelete(game) : () => { }}
                      title="delete"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </li>
                )
              })
            )}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default withUserContext(GamesList);