import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withUserContext } from './../../contexts/user';

function PrivateRoute ({component: Component, ...rest}) {
    if(rest.userContext.isLoading) {
        return (
            <p className="fs-5">
                <i className="fas fa-spinner spin mb-3"></i>
                <span className="ms-3">
                    Loading user information.
                </span>
            </p>
        );
    }

    return (
        <Route
            {...rest}
            render={(props) => rest.userContext.user
                ? <Component {...props} />
                : <Redirect to={{pathname: '/login', state: {from: rest.location.pathname}}} />}
        />
    )
}

export default withUserContext(PrivateRoute)