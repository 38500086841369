import { useState } from "react";
import cn from "classnames"
import { Button, Modal } from "react-bootstrap";
import "./PrivacyStatement.css";

type PrivacyStatementProps = {
  className?: string
}

const CONTACT_EMAIL = "admin@unitedpadel.org";

export const PrivacyStatement = ({ className }: PrivacyStatementProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="privacy-statement">
      <p className={cn({ [`${className}`]: !!className })}>
        <span>By using this app you agree to&nbsp;</span>
        <Button className="p-0" variant="link" onClick={() => setShowModal(true)}>
          United Padel's Privacty Statement
        </Button>.
      </p>
      {showModal && (
        <Modal
          show
          className={className}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              United Padel's Privacy Statement
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Content created using https://www.privacy.org.nz/tools/privacy-statement-generator/ */}
            <p>
              We collect personal information from you, including information about your:
              name, email address, avatar, phone number.
            </p>
            <p>
              We collect your personal information in order to consolidate a database of players for our padel community.
            </p>
            <p>
              You have the right to ask for a copy of any personal information we hold about you,
              and to ask for it to be corrected if you think it is wrong.
              If you would like to ask for a copy of your information, or to have it corrected,
              please contact us at <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}