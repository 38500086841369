import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { UserContextProps, withUserContext } from "../../contexts/user";
import { arrayUnion, collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { RANKING, TAccount } from "../../types/Account";
import { FEATURE_FLAGS, isFeatureEnable } from "../../helpers/featureFlags";
import Feedback from "../common/Feedback/Feedback";
import { getAccountByEmail } from "../../helpers/account";

type PlayerProps = UserContextProps & RouteComponentProps<{ id: string }>

const Player = ({ userContext, match, history }: PlayerProps) => {
    const [player, setPlayer] = useState<TAccount>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [alreadyGaveFeedback, setAlreadyGaveFeedback] = useState(false);

    const { user } = userContext;
    const playerId = match.params.id;

    useEffect(() => {
        if (!isFeatureEnable(FEATURE_FLAGS.RANKING) || playerId === user?.email) {
            history.replace("/");
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!playerId || !user) {
            return;
        }

        getAccountByEmail(playerId).then(account => {
            if (!!account) {
                setPlayer(account);
                const existingFeedback = account.feedback?.find((f) => f.from === user.email);
                if (existingFeedback) {
                    setAlreadyGaveFeedback(true);
                }
            }
            setIsLoading(false);
        });
    }, [playerId, user]);

    if (isLoading) {
        return (
            <p className="fs-5">
                <i className="fas fa-spinner spin mb-3"></i>
                <span className="ms-3">
                    Loading user information.
                </span>
            </p>
        )
    }

    if (!player) {
        return (
            <p className="fs-5">No player found with id {playerId}</p>
        )
    }

    const onSubmitFeedback = async (feedback: string[]) => {
        setIsSubmitting(true);
        const accountRef = doc(collection(getFirestore(), "accounts"), playerId);
        await setDoc(accountRef!, {
            feedback: arrayUnion({
                feedback,
                from: user?.email,
            }),
        }, { merge: true });
        setAlreadyGaveFeedback(true);
        setIsSubmitting(false);
    }

    const nameOrMail = player.name || playerId;
    const hasRanking = player?.ranking;

    const renderFeedback = () => {
        if(alreadyGaveFeedback) {
            return (
                <>
                    <p className="mb-1">We've received your feedback.</p>
                    <p>Our team will review it to re-calculate {nameOrMail}'s ranking</p>
                </>
            );
        }

        return (
            <Feedback initialFeedback={player?.ranking} className="mb-4" isDisabled={isSubmitting} onSubmit={onSubmitFeedback}
                note={(
                    <>
                        <p className="mb-1">Your feedback help us improve rankings. They will never be made public and only our web team will have access to them.</p>
                        <p className="text-secondary-emphasis mb-3">Please choose two consecutive levels.</p>
                    </>
                )}
            />
        );
    }

    return (
        <div className="game col col-lg-9 col-xl-8 col-xxl-7">
            <h4 className="mb-2">{nameOrMail}'s profile</h4>
            {hasRanking && (
                <p>
                    Current ranking:
                    {player?.ranking.map((r, index) => {
                        const title = RANKING[r as keyof typeof RANKING]
                        return (
                            <span key={title}>
                                &nbsp;<strong>{title}</strong>
                                {index === 0 && (
                                    <span>&nbsp;-</span>
                                )}
                            </span>
                        );
                    })}
                </p>
            )}

            <h5 className="mt-5 mb-2">Give feedback</h5>
            {hasRanking ? (
                renderFeedback()
            ) : (
                <p>This player hasn't enabled ranking so feedback is not available yet.</p>
            )}
        </div>
    )
}

export default withUserContext(Player);
