export enum FEATURE_FLAGS {
  NOTIFICATIONS = 'VITE_FEATURE_FLAG_NOTIFICATIONS_ENABLED',
  RANKING = 'VITE_FEATURE_FLAG_RANKING_ENABLED',
}

export const GUINEA_PIGS: string[] = [
  // "matiasfarulla@gmail.com",
  // "tatianasaviogarcia@gmail.com",
  // "anadc02@gmail.com",
  // "eall093@aucklanduni.ac.nz",
  // "juani027@gmail.com",
  // "kazeman@gmail.com",
  // "ddelyerro@gmail.com",
  // "emanuelherrmann@gmail.com",
];

export const isFeatureEnable = (featureFlag: string, email?: string|null) => {
  if (import.meta.env[featureFlag] === 'true') {
    return true;
  }

  if (!!email) {
    return GUINEA_PIGS.includes(email.toLocaleLowerCase());
  }

  return false;
}

const ADMINS = [
  "matiasfarulla@gmail.com",
  "tatianasaviogarcia@gmail.com",
  "ddelyerro@gmail.com",
];

export const isAdmin = (email?: string|null) => {
  if (import.meta.env.DEV) {
    return true;
  }
  return !!email && ADMINS.includes(email)
};