
import { CAN_ADD_TYPE } from "./types/Game";

export const CAN_ADD_VALUES: { [key in CAN_ADD_TYPE]: CAN_ADD_TYPE} = {
    ME: 'ME',
    BOOKERS: 'BOOKERS',
    ANYONE: 'ANYONE',    
}
export const CAN_ADD_VALUES_LABELS: { [key: string]: string } = {
    [CAN_ADD_VALUES.ME]: 'Only me',
    [CAN_ADD_VALUES.BOOKERS]: 'Only bookers',
    [CAN_ADD_VALUES.ANYONE]: 'Anyone',
}

export const CAN_ADD_OPTIONS = Object.values(CAN_ADD_VALUES).map(key => ({
    value: key,
    label: CAN_ADD_VALUES_LABELS[key]
}))