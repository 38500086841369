
export const findAllSW = () => {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
      const url = registration.active?.scriptURL
      if (!!url) {
        alert(`scriptURL ${url}`);
      }
    } 
  });
}

export const unregisterAllSW = () => {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
      registration.unregister();
    } 
  });
}
