import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getFirestore, updateDoc, arrayUnion } from "firebase/firestore";
import { subWeeks } from "date-fns";
import { UserContextProps, withUserContext } from '../../contexts/user';
import GamesList from '../common/GamesList/GamesList';
import './Home.css';
import { TGame } from '../../types/Game';
import { getLogEntry } from '../../helpers/logs';

type HomeProps = UserContextProps

const Home = (props: HomeProps) => {
    const [ownedGames, setOwnedGames] = useState<TGame[]>([]);
    const [invitedGames, setInvitedGames] = useState<TGame[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const { user } = props.userContext;

    useEffect(() => {
        if (!!user) {
            watchGames();
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    const watchGames = () => {
        Promise.all([
            watchInvitedGames(),
            watchOwnedGames(),
        ]).then(() => setIsLoading(false))
    }

    const watchInvitedGames = () => {
        if (!user) {
            return;
        }
        const lastWeekString = subWeeks(new Date(), 1);
        const q = query(
            collection(getFirestore(), "games"),
            where("playersNames", "array-contains", user.displayName),
            where("time", ">=", lastWeekString),
            where("isDeleted", "==", false),
        )
        return getDocs(q).then(querySnapshot => {
            if (querySnapshot.empty) {
                setInvitedGames([]);
                return;
            }

            const games: TGame[] = [];
            querySnapshot.forEach(doc => {
                const game = doc.data() as TGame;
                if (game.owner.email !== user.email) {
                    games.push({
                        ...game,
                        id: doc.ref.id,
                    });
                }
            });

            setInvitedGames(games);
        }).catch(e => {
            console.error(e)
        })
    }

    const watchOwnedGames = () => {
        if (!user) {
            return;
        }
        const lastWeekString = subWeeks(new Date(), 1);
        const q = query(
            collection(getFirestore(), "games"),
            where("owner.email", "==", user.email),
            where("time", ">=", lastWeekString),
            where("isDeleted", "==", false),
        )
        return getDocs(q).then(querySnapshot => {
            if (querySnapshot.empty) {
                setOwnedGames([]);
                return;
            }

            const games: TGame[] = [];
            querySnapshot.forEach(doc => {
                const game = doc.data() as TGame;
                games.push({
                    ...game,
                    id: doc.ref.id,
                });
            });

            setOwnedGames(games);
        });
    }

    const deleteGame = (game: TGame) => {
        if (!confirm(`Are you sure you want to delete ${game.name}?`)) {
            return;
        }
        const gameRef = doc(getFirestore(), "games", game.id);
        updateDoc(gameRef, {
            isDeleted: true,
            events: arrayUnion(getLogEntry("Game deleted", user?.displayName)),
        }).then(() => {
            setOwnedGames(prevState => prevState.filter(g => g.id !== game.id))
        }).catch(function (error) {
            console.error("Error removing document: ", error);
        });
    }

    return (
        <div className="col col-lg-9 col-xl-8 col-xxl-7">
            <GamesList title="Games you've created" games={ownedGames} isLoading={isLoading} handleDelete={deleteGame} userUID={user?.uid} />
            <GamesList title="Games you've accepted" games={invitedGames} isLoading={isLoading} userUID={user?.uid} />

            <Link to="/game/new" className="btn btn-primary">Create game</Link>
        </div>
    )
}

export default withUserContext(Home);