import { STORAGE_KEYS } from "./storage";
import { getTokenWithVerifiedWorker } from "./firebase";

export const getNotificationsToken = (): Promise<string|undefined> => {
    console.log("getNotificationsToken pre Notification.requestPermission")
    return Notification.requestPermission()
        .then(async (permission) => {
            if (permission === 'granted') {
                const notificationsToken = (await getTokenWithVerifiedWorker()) || "";
                localStorage.setItem(STORAGE_KEYS.NOTIFICATIONS_TOKEN, notificationsToken);
                return notificationsToken;
            } else {
                console.error("Permission not granted. Showing error: Notifications are not enabled in this browser. Please check your settings");
                alert("Notifications are not enabled in this browser. Please check your settings");
            }
        })
        .catch(err => {
            console.error("Notification.requestPermission catch with error:", err);
            alert(err);
            return undefined;
        });
}

export const turnOffNotifications = () => {
    localStorage.setItem(STORAGE_KEYS.NOTIFICATIONS_TOKEN, "");
    return null;
}