import React from "react";
import "./NotFound.css";
import { Link } from "react-router-dom/cjs/react-router-dom";

const NotFound = () => (
  <div className="not-found-page">
    <h3 className="title">404</h3>
    <h4 className="subtitle mb-4">Page Not Found</h4>
    <p className="mb-2">The specified file was not found on this website.</p>
    <p>Please check the URL for mistakes or go back to the <Link to="/">homepage</Link></p>
  </div>
)

export default NotFound;