import { Timestamp } from "firebase/firestore"
import { Player } from "./Player"

export type CAN_ADD_TYPE = "ME" | "ANYONE" | "BOOKERS";

export enum VISIBILITY {
  NORMAL_LOOKAHEAD = "NORMAL_LOOKAHEAD",
  ALWAYS_VISIBLE = "ALWAYS_VISIBLE",
  NOT_VISIBLE = "NOT_VISIBLE",
}

export type TOwner = {
  email: string;
  name: string;
  uid: string;
  token?: string | null;
}

export type TGame = {
  id: string
  canAdd: CAN_ADD_TYPE
  community?: string
  description?: string
  endTime?: string
  events: string[]
  location?: string
  name: string
  owner: TOwner
  players: Player[]
  playersNames: string[]
  playersNeeded: number
  time: Timestamp
  isDeleted: boolean
  createdOn?: Timestamp
  alwaysVisible?: boolean // Deprecated. Use visibility instead.
  ranking?: string
  visibility?: VISIBILITY
}