import { format } from 'date-fns';

const getPrettyNow = () => format(new Date(), 'dd/MM/yyyy HH:mm:ss');

export const getLogEntry = (action, userName) => {
  const logBase = `${getPrettyNow()} - ${action}`;
  if (userName) {
    return `${logBase} by ${userName}`;
  }
  return logBase;
}