export enum RANKING {
  Beginner = 1,
  Improver = 2,
  Experienced = 3,
  Advanced = 4,
}

export const RANKING_DESCRIPTIONS = {
  [RANKING.Beginner]: "You have just started playing padel and you are in a learning phase. You don't know tactics and positioning. You make slow returns and have difficulties with walls and long rallies.",
  [RANKING.Improver]: "You show consistency with medium speed shots. Not 100% comfortable with all shots, but you progressed in positioning and mid-range shots' direction and control. You still make unforced errors.",
  [RANKING.Experienced]: "You can anticipate the ball effects and trajectories, and know how to position yourself. You make less unforced errors, can control the depth of your shots and adjust your game plan. Forehand & volley have greatly improved.",
  [RANKING.Advanced]: "You dominate your forehand, using it effectively for game building and transitions at the net. You can combine your shots to exploit opponents' weaknesses. You anticipate and deal with difficult situtations.",
}

export type TFeedback = {
  feedback: string[]
  from: string
}

export type TAccount = {
  notificationsToken: string
  name?: string
  feedback: TFeedback[]
  ranking: string[]
}
