import { useEffect, useState } from "react";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { Card } from "react-bootstrap";
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import StyledFirebaseAuth from './StyledFirebaseAuth';
import { UserContextProps, withUserContext } from '../../contexts/user';
import { PrivacyStatement } from "../PrivacyStatement/PrivacyStatement";
import NotificationsToggle from "./NotificationsToggle";
import { isUnitedPadelHostname } from "../../helpers/communities";
import { findAllSW, unregisterAllSW } from "../../helpers/serviceWorkers";
import { FEATURE_FLAGS, isAdmin, isFeatureEnable } from "../../helpers/featureFlags";
import Feedback from "../common/Feedback/Feedback";

declare let eruda: any; // eslint-disable-line @typescript-eslint/no-explicit-any

type AccountProps = UserContextProps & RouteComponentProps<null, {}, { from?: string }> // eslint-disable-line @typescript-eslint/no-empty-object-type

const Account = (props: AccountProps) => {
    const { location, userContext } = props;
    const { user, account, signOut, isLoading } = userContext;

    const [isSavingFeedback, setIsSavingFeedback] = useState(false);

    const uiConfig: firebaseui.auth.Config = {
        signInFlow: 'popup', // Popup signin flow rather than redirect flow.
        signInOptions: [
            new GoogleAuthProvider().providerId,
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => {
                const { from } = location.state || {};
                if (!!from) {
                    props.history.push(from);
                } else {
                    props.history.push("/");
                }
                return true;
            },
        }
    };

    // Update name on account table.
    useEffect(() => {
        if (!user?.displayName || !account || !!account?.name) {
            return;
        }

        if (!!user?.displayName && user.displayName !== account.name) {
            const accountRef = doc(collection(getFirestore(), "accounts"), user!.email!);
            setDoc(accountRef!, { name: user?.displayName }, { merge: true });
        }
    }, [user, account]);

    const onRankingSubmit = async (feedback: string[]) => {
        setIsSavingFeedback(true);
        const accountRef = doc(collection(getFirestore(), "accounts"), user!.email!);
        await setDoc(accountRef!, {
            ranking: feedback,
        }, { merge: true });
        setIsSavingFeedback(false);
    }

    if (isLoading) {
        return (
            <p className="fs-5">
                <i className="fas fa-spinner spin mb-3"></i>
                <span className="ms-3">
                    Loading user information.
                </span>
            </p>
        )
    }

    const renderWelcome = () => {
        if (!!user) {
            return (
                <h4 className="my-4">Welcome {user.displayName}!</h4>
            )
        }

        return (
            <>
                <h4 className="my-4">Welcome to United Padel's booking system</h4>
                <p>We are happy to have you and encourage you to consider joining our padel community.</p>
            </>
        )
    }

    const renderRanking = () => {
        if (!account) {
            return (
                <i className="fas fa-spinner spin mb-3"></i>
            )
        }

        if (!!account.ranking) {
            return (
                <>
                    <p>This is your current ranking. It will adjust itself with feedback from other players.</p>
                    <Feedback initialFeedback={account.ranking} />
                </>
            )
        }

        return (
            <>
                <p>The first step to set your ranking is self-reflection. How do you see yourself?</p>
                <Feedback
                    onSubmit={onRankingSubmit}
                    isDisabled={isSavingFeedback}
                    note={<>
                        <p className="text-secondary-emphasis mt-2 mb-1">Please choose two. Your current level and:</p>
                        <ul>
                            <li>a level above to be able to play with better players and help you improve or</li>
                            <li>a level below to help others improve their game</li>
                        </ul>
                    </>}
                />
            </>
        )
    }

    return (
        <div className="login col col-lg-8 col-xl-6">
            {renderWelcome()}

            {!!user && isFeatureEnable(FEATURE_FLAGS.NOTIFICATIONS, user.email) && (
                <Card className="mb-4">
                    <Card.Header>
                        <h5 className="mb-0">Notifications</h5>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text as="div">
                            <NotificationsToggle />
                        </Card.Text>

                    </Card.Body>
                </Card>
            )}

            {!!user && isFeatureEnable(FEATURE_FLAGS.RANKING, user.email) && (
                <Card className="mb-4">
                    <Card.Header>
                        <h5 className="mb-0">Ranking</h5>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text as="div">
                            {renderRanking()}
                        </Card.Text>
                    </Card.Body>
                </Card>
            )}

            {isUnitedPadelHostname() && (
                <Card className="mt-4">
                    <Card.Header>
                        <h5 className="mb-0">Disclaimer</h5>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text as="div">
                            <PrivacyStatement className="mb-0" />
                        </Card.Text>
                    </Card.Body>
                </Card>
            )}

            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Login</h5>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        {!!user ? (
                            <>
                                <p>You are logged in as <strong>{user?.email}</strong></p>
                                <button className="btn btn-secondary mt-2" type="button" onClick={signOut}>Logout</button>
                            </>
                        ) : (
                            <StyledFirebaseAuth className="mt-4 me-0" uiConfig={uiConfig} firebaseAuth={getAuth()} />
                        )}
                    </Card.Text>
                </Card.Body>
            </Card>

            <footer className="d-flex flex-row mt-5">
                <p className="m-0 lead">version {import.meta.env.VITE_VERSION}</p>
                {isAdmin(user?.email) && (
                    <>
                        <button className="ms-4" onClick={() => eruda.init()}>Open console</button>
                        <button className="ms-4" onClick={() => findAllSW()}>Find all workers</button>
                        <button className="ms-4" onClick={() => unregisterAllSW()}>Unregister workers</button>
                        <button className="ms-4" onClick={() => window.location.reload()}>Reload page</button>
                    </>
                )}
            </footer>
        </div>
    );
}

export default withRouter(withUserContext(Account));