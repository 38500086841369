import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { STORAGE_KEYS } from "./storage";

const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(config);
if (!!import.meta.env.VITE_RECAPTCHA_SITE_KEY) {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(import.meta.env.VITE_RECAPTCHA_SITE_KEY),
    isTokenAutoRefreshEnabled: true // Optional. If true, the SDK automatically refreshes App Check tokens as needed.
  });
}

export const db = getFirestore(app);
export const auth = getAuth(app);
const functions = getFunctions(app, "us-central1");
const messaging = getMessaging(app);
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  const {title, body} = payload.notification;
  // Show a better notification that handles clicks.
  alert(`${title}: ${body}`);
});

const getWorkerOrRegister = async () => {
  const isLocal = import.meta.env.MODE === 'local';
  const swPath = isLocal ? '/dev-sw.js?dev-sw' : '/sw.js';
  if ("serviceWorker" in navigator) {
    const r = await navigator.serviceWorker.getRegistration(swPath);
    if (!!r) {
      console.log("getWorkerOrRegister: worker already registered", r)
      return r;
    }
    console.log(`Not registered ${swPath} Registering now.`);
    await navigator.serviceWorker.register(swPath, { type: isLocal ? 'module' : 'classic' });
    return navigator.serviceWorker.ready.then((r) => r);
  }
}

export const getTokenWithVerifiedWorker = () => {
  return getWorkerOrRegister().then((registration) => {
    console.log("getTokenWithVerifiedWorker: returned registration", registration)
    return getToken(messaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
      serviceWorkerRegistration: registration,
    })
  }).then((currentToken) => {
    if (currentToken) {
      localStorage.setItem(STORAGE_KEYS.NOTIFICATIONS_TOKEN, currentToken);
      return currentToken;
    }
    console.log('No registration token available. Request permission to generate one.');
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}