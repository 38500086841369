// Detects if the device is on iOS 
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

const isSafari = () => /^((?!CriOS|FxiOS).)*safari/i.test(window.navigator.userAgent);

// Detects if device is in standalone mode
const isInStandaloneMode = () => (('standalone' in window.navigator) && (window.navigator.standalone)) || window.matchMedia('(display-mode: standalone)').matches;

export const isIOSBrowser = () => isIos() && !isInStandaloneMode();

export const isIosSafari = () => isIOSBrowser() && isSafari();