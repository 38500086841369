import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { TAccount } from "../types/Account";

export const getAccountByEmail = (email: string): Promise<TAccount|undefined> => {
  const accountRef = doc(collection(getFirestore(), "accounts"), email!);
  return getDoc(accountRef).then((snap) => {
    if (!snap.exists()) {
      return;
    }
    return snap.data() as TAccount;
  });
}