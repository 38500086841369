import React, { useState, useEffect, FormEvent } from 'react';
import { getFirestore, doc, collection, getDoc, updateDoc, setDoc, Timestamp, arrayUnion, FieldValue } from "firebase/firestore";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { addHours } from "date-fns/addHours";
import { addMonths } from "date-fns/addMonths";
import { endOfDay } from "date-fns/endOfDay";
import { format } from 'date-fns/format';
import { isValid } from "date-fns/isValid";
import { isWithinInterval } from 'date-fns/isWithinInterval';
import { startOfHour } from 'date-fns/startOfHour';
import { Form } from "react-bootstrap";
import { UserContextProps, withUserContext } from '../../contexts/user';
import { CAN_ADD_OPTIONS, CAN_ADD_VALUES } from '../../variables';
import { TGame, VISIBILITY } from '../../types/Game';
import { getLogEntry } from "../../helpers/logs";
import { isUnitedPadelHostname, UNITED_PADEL_ID } from "../../helpers/communities";
import { COMMUNITY_MEMBERS_MAX_LOOKAHEAD_DAYS, isPastGame } from '../../helpers/game';
import { FEATURE_FLAGS, isFeatureEnable } from '../../helpers/featureFlags';
import "./Create.css";
import { RANKING } from '../../types/Account';

const DEFAULT_PLAYERS_NEEDED = 8;

type CreateProps = UserContextProps & RouteComponentProps<{ id: string }>

type GameBeingCreated = Partial<TGame> & { events: FieldValue|string[] };

const Create = (props: CreateProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [communities, setCommunities] = useState([]);

    const gameId = props.match.params.id;
    const communityIdParam = new URLSearchParams(props.location.search).get("community");
    const [game, setGame] = useState<GameBeingCreated>({
        name: '',
        time: undefined,
        endTime: undefined,
        location: '',
        description: '',
        players: [],
        canAdd: CAN_ADD_VALUES.BOOKERS,
        events: [],
        playersNames: [],
        playersNeeded: DEFAULT_PLAYERS_NEEDED,
        community: communityIdParam || '',
        isDeleted: false,
        owner: undefined,
        alwaysVisible: false,
        ranking: "",
        visibility: VISIBILITY.NORMAL_LOOKAHEAD
    });

    useEffect(() => {
        if (!gameId) {
            setIsLoading(false);
            return;
        }
        const docRef = doc(collection(getFirestore(), "games"), gameId);
        getDoc(docRef).then(doc => {
            if(doc.exists()) {
                const gameData = doc.data();
                setGame(gameData as TGame);
            }
        });
    }, [gameId]);

    const { user } = props.userContext;
    useEffect(() => {
        if (!user) {
            return;
        }
        const docRef = doc(collection(getFirestore(), "users"), user.email as string);
        getDoc(docRef).then(doc => {
            if(doc.exists()) {
                const userDoc = doc.data();
                setCommunities(userDoc.communities);
            }
        });
    }, [user]);

    useEffect(() => {
        if (!game?.owner || !user) {
            return;
        }
        if (!user && !gameId) {
            props.history.replace("/");
        }
        if (game.owner?.email !== user.email || isPastGame(game as TGame)) {
            props.history.replace("/");
        } else {
            setIsLoading(false);
        }
    }, [game, user, gameId, props.history]);

    const randomString = (length: number) => {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }

    const createGameId = async (name: string) => {
        const id = `${name.replace(/\s/g, "-")}-${randomString(10)}`
        const docRef = doc(collection(getFirestore(), "games"), id);
        const docQuery = await getDoc(docRef);
        if(!docQuery.exists()) {
            return id;
        }
        return createGameId(name);
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!!e.currentTarget.checkValidity && !e.currentTarget.checkValidity()) {
            alert("Please verify the values you provided as the form submittion is not valid");
            return;
        }
        setIsSubmitting(true)

        const logAction = !!gameId ? "Game edited" : "Game created";
        const gameEditedLogEntry = getLogEntry(logAction, user!.displayName);
        const gameIdOrNew = gameId ?? await createGameId(game.name!);
        const communityId =  game.community ?? isUnitedPadelHostname() ? UNITED_PADEL_ID : "";

        const gameRef = doc(getFirestore(), "games", gameIdOrNew);
        const promise = !!gameId ? updateDoc(gameRef, {
            name: game.name,
            canAdd: game.canAdd,
            time: game.time,
            endTime: game.endTime,
            community: communityId,
            events: arrayUnion(gameEditedLogEntry),
            playersNeeded: game.playersNeeded,
            description: game.description,
            alwaysVisible: null,
            ranking: game.ranking,
            visibility: game.visibility
        }) : setDoc(gameRef, {
            ...game,
            owner: {
                email: user!.email,
                name: user!.displayName,
                uid: user!.uid,
            },
            playersNames: [],
            players: [],
            events: [gameEditedLogEntry],
            community: communityId,
            ranking: game.ranking,
            createdOn: Timestamp.fromDate(new Date()),
        } as TGame);
    
        promise.then(() => {
            props.history.push(`/game/${gameIdOrNew}`);
        }).catch(() => setIsSubmitting(false));
    }

    const handleInputChange = (e: React.FormEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => {
        const target = e.currentTarget;
        const { name, value } = target;
        setGame(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleVisibilityChange = (value: VISIBILITY) => {
        setGame(prevState => ({
            ...prevState,
            visibility: value
        }));
    }

    const minStartDate = startOfHour(addHours(new Date(), 1));
    const maxStartDate = addMonths(new Date(), 2);
    const handleDateChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
        const name = currentTarget.name;
        const chosenDate = new Date(currentTarget.value);
        if (
            isValid(chosenDate) &&
            isWithinInterval(chosenDate, { start: minStartDate, end: maxStartDate })
        ) {
            setGame(prevState => ({
                ...prevState,
                [name]: Timestamp.fromDate(chosenDate)
            }));
        }
    }

    const handleNumberChange = ({ currentTarget }: React.FormEvent<HTMLInputElement>) => {
        setGame(prevState => ({
            ...prevState,
            [currentTarget.name]: Number(currentTarget.value)
        }));
    }

    if (isLoading) {
        return (
            <p className="fs-5">
                <i className="fas fa-spinner spin mb-3"></i>
                <span className="ms-3">
                    Loading screen.
                </span>
            </p>
        )
    }

    const isVisibilityOptionChecked = (game: GameBeingCreated, value: VISIBILITY) => {
        if (!game.visibility) {
            return game.alwaysVisible ? value === VISIBILITY.ALWAYS_VISIBLE : value === VISIBILITY.NORMAL_LOOKAHEAD;
        }
        return game.visibility === value;
    }

    const VISIBILITY_LABEL = {
        [VISIBILITY.NORMAL_LOOKAHEAD]: <>
            <span>
                {COMMUNITY_MEMBERS_MAX_LOOKAHEAD_DAYS} days before
            </span>
            <em className="ms-2">(default)</em>
        </>,
        [VISIBILITY.ALWAYS_VISIBLE]: "Always visible",
        [VISIBILITY.NOT_VISIBLE]: "Not visible",
    }

    const renderVisibilityOptions = (game: GameBeingCreated) => {
        return Object.keys(VISIBILITY).map((key) => {
            const value = VISIBILITY[key as keyof typeof VISIBILITY];
            const isChecked = isVisibilityOptionChecked(game, value);
            const elementId = value.toString();
            return (
                <div className="form-check" key={elementId}>
                    <input className="form-check-input" type="radio" name="visibility" key={game.visibility} id={elementId} value={value} defaultChecked={isChecked} onChange={() => handleVisibilityChange(value)} />
                    <label className="form-check-label" htmlFor={elementId}>
                        {VISIBILITY_LABEL[value]}
                    </label>
                </div>
            );
        });
    }

    const datetime = game.time ? format(game.time.toDate(), "yyyy-MM-dd'T'HH:mm") : undefined;
    const startDateOrToday = game.time?.toDate() || new Date();

    return (
        <div className="create-game col col-lg-8 col-xl-6">
            <h3>{`${!!gameId ? "Edit" : "Create"} game`}</h3>
            <form onSubmit={onSubmit}>
                <div className="mb-3">
                    <label className="form-label" htmlFor="name">Name <small className="ms-1">(required)</small></label>
                    <input type="text" defaultValue={game.name} onChange={handleInputChange} className="form-control" name="name" id="name" required />
                </div>
                <div className="mb-3 row">
                    <div className="col-6">
                        <label className="form-label" htmlFor="time">Start <small className="ms-1">(required)</small></label>
                        <input
                            type="datetime-local"
                            min={format(minStartDate, "yyyy-MM-dd'T'HH':00'")}
                            max={format(maxStartDate, "yyyy-MM-dd'T'HH':00'")}
                            defaultValue={datetime}
                            onChange={handleDateChange}
                            className="form-control" name="time" id="time" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label" htmlFor="endTime">End time</label>
                        <input
                            type="time"
                            min={format(startDateOrToday, "HH:mm")}
                            max={format(endOfDay(startDateOrToday), "HH:mm")}
                            defaultValue={game.endTime || undefined}
                            onChange={handleInputChange}
                            className="form-control" name="endTime" id="endTime" />
                    </div>
                </div>
                {/* <div className="mb-3">
                    <label className="form-label" htmlFor="location">Location</label>
                    <input type="text" className="form-control" value={game.location} onChange={handleInputChange} name="location" id="location" placeholder="Location" required />
                </div> */}
                <div className="mb-3">
                    <label className="form-label" htmlFor="description">Description</label>
                    <textarea className="form-control" defaultValue={game.description} onChange={handleInputChange} name="description" id="description" rows={3} />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="playersNeeded">How many players?</label>
                    <input key={game?.playersNeeded} name="playersNeeded" id="playersNeeded"
                        type="number" className="form-control"
                        min="4" max="22" step="1" required
                        defaultValue={game?.playersNeeded || DEFAULT_PLAYERS_NEEDED}
                        onChange={handleNumberChange}
                    />
                </div>
                {!!user && isFeatureEnable(FEATURE_FLAGS.RANKING, user.email) && (
                    <div className="mb-3">
                        <label className="form-label" htmlFor="ranking">Players ranking</label>
                        <Form.Select id="ranking" name="ranking" aria-label="Select ranking" value={game?.ranking} onChange={handleInputChange}>
                            <option value="">Anyone</option>
                            {Object.keys(RANKING)
                                .filter((k) => !isNaN(k as any)) // eslint-disable-line @typescript-eslint/no-explicit-any
                                .map(k => {
                                    const label = RANKING[Number(k)];
                                    return (
                                        <option value={k}>{label}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </div>
                )}
                <div className="mb-3">
                    <label className="form-label me-3">Who can add players?</label>
                    <div>
                        {CAN_ADD_OPTIONS.map(({value, label}) => (
                            <div key={value+game.canAdd} className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="canAdd" id={value} value={value} defaultChecked={game.canAdd === value} onChange={handleInputChange} />
                                <label className="form-check-label" htmlFor={value}>{label}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label me-3">Game visibility:</label>
                    {renderVisibilityOptions(game)}
                </div>
                {!isUnitedPadelHostname() && !!communities.length && (
                    <div className="mb-3">
                        <label className="form-label" htmlFor="community">This game is part of a community</label>
                        <select defaultValue="" className="form-select" aria-label="communities picker" onChange={handleInputChange} name="community" id="community">
                            <option value="">None</option>
                            {communities.map(communityName => (
                                <option key={communityName} value={communityName}>{communityName}</option>
                            ))}
                        </select>
                    </div>
                )}
                <div className="mt-4">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        {!!gameId ? "Update" : "Create"}
                    </button>
                    <button type="button" className="btn btn-secondary ms-3" disabled={isSubmitting} onClick={() => props.history.goBack()}>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    )
}

export default withRouter(withUserContext(Create));