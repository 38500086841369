import { User } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { TCommunity } from "../types/Community";

type useIsBookerProps = {
  user: User | null
  communityId?: string
}

export const useIsBooker = ({ user, communityId }: useIsBookerProps) => {
  const [isBooker, setIsBooker] = useState(false);

  useEffect(() => {
    if (!user?.email || !communityId) {
        return;
    }

    const docRef = doc(getFirestore(), "communities", communityId);
    getDoc(docRef).then((docSnap) => {
        if (!docSnap.exists()) {
            return;
        }
        const doc = docSnap.data() as TCommunity;
        if ((doc.bookers || []).includes(user.email!)) {
            setIsBooker(true);
        }
    });
  }, [user?.email, communityId]);

  return isBooker;
}
