import { addHours } from 'date-fns/addHours';
import { format } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import { setHours } from "date-fns/setHours";
import { setMinutes } from "date-fns/setMinutes";
import { addDays } from 'date-fns/addDays';
import { addMonths } from 'date-fns/addMonths';
import { endOfDay } from 'date-fns/endOfDay';
import { TGame } from "../types/Game";

export const isUserGameOwner = (game?: TGame, uid?: string) => !!game && !!uid && uid === game.owner.uid;

const HARDCODED_DURATION = 1.5;
const getEndDateTime = (startDate: Date, endTime?: string) => {
    if (!endTime) {
        return addHours(new Date(startDate), HARDCODED_DURATION)
    }
    const [timeToHours, timeToMinutes] = endTime.split(":");
    return setMinutes(setHours(new Date(startDate), Number(timeToHours)), Number(timeToMinutes))
}

export const getCalendarHref = (game: TGame) => {
    if (!game) {
        return;
    }

    const GOOGLE_CALENDAR_DATE_FORMAT = `yyyyMMdd'T'HHmmss`;
    const base = "http://www.google.com/calendar/event?";
    const title = encodeURIComponent(game.name);
    const time = game.time.toDate();
    const timeFrom = format(time, GOOGLE_CALENDAR_DATE_FORMAT); // 20131124T010000Z
    const timeTo = format(getEndDateTime(game.time.toDate(), game.endTime), GOOGLE_CALENDAR_DATE_FORMAT);
    const dates = `${timeFrom}/${timeTo}`;
    const description = encodeURIComponent(game.description || "");
    return `${base}action=TEMPLATE&text=${title}&dates=${dates}&details=${description}&location=${game.location}`;
}

export const isPastGame = (game: TGame) => {
    if (!game?.time) {
        return;
    }
    const endTime = getEndDateTime(game.time.toDate(), game.endTime)
    return isBefore(endTime, new Date());
}

export const COMMUNITY_MEMBERS_MAX_LOOKAHEAD_DAYS = 7;
export const bookersLookahead = endOfDay(addMonths(new Date(), 2));
export const communityMembersMaxLookahead = endOfDay(addDays(new Date(), COMMUNITY_MEMBERS_MAX_LOOKAHEAD_DAYS));