import React, { useEffect, useState } from 'react'
import { onAuthStateChanged, getAuth, User } from "firebase/auth";
import { collection, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { TAccount } from '../types/Account';

type Props = {
    signOut: () => void
    user: User|null
    account?: TAccount
    isLoading: boolean
}
export type UserContextProps = {
    userContext: Props
}
const UserContext = React.createContext<Props>({
    signOut: () => {},
    user: null,
    account: undefined,
    isLoading: true,
});

const UserProvider = ({ children }: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<User|null>(null);
    const [account, setAccount] = useState<TAccount>();

    useEffect(() => {
        const unregisterAuthObserver = onAuthStateChanged(getAuth(), (user) => {
            setUser(user);
        });
        return () => {
            if(unregisterAuthObserver) {
                unregisterAuthObserver();
            }
        }
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }

        const accountRef = doc(collection(getFirestore(), "accounts"), user.email!);
        const unsub = onSnapshot(accountRef, (snap) => {
          if (snap.exists()) {
            setAccount(snap.data() as TAccount);
          }
          setIsLoading(false);
        });
        return () => {
          unsub();
        }
    }, [user]);

    const handleSignOut = async () => {
        setIsLoading(true);
        await getAuth().signOut();
        setUser(null);
        setAccount(undefined);
        setIsLoading(false);
    }

    return (
        <UserContext.Provider value={{
            signOut: handleSignOut,
            user,
            account,
            isLoading,
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;

export function withUserContext(Component: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    return function WrapperComponent(props: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        return (
            <UserContext.Consumer>
                {state => <Component {...props} userContext={state} />}
            </UserContext.Consumer>
        )
    }
}