import { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import cn from "classnames"
import { isSupported } from "firebase/messaging";
import { getNotificationsToken, turnOffNotifications } from "../../helpers/notifications";
import { FEATURE_FLAGS, isFeatureEnable } from "../../helpers/featureFlags";
import { isIos, isIosSafari } from "../../helpers/device";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { UserContextProps, withUserContext } from "../../contexts/user";
import { STORAGE_KEYS } from "../../helpers/storage";

type NotificationsToggleProps = {
  className?: string
} & UserContextProps

const NotificationsToggle = ({ className, userContext }: NotificationsToggleProps) => {
  const { account, user } = userContext;
  const [isUpdatingNotification, setIsUpdatingNotification] = useState(false);
  const [isNotificationsSubscribed, setIsNotificationsSubscribed] = useState(false);
  const [showSafariWizard, setShowSafariWizard] = useState(false);
  const [notificationsAllowed, setNotificationsAllowed] = useState(false);

  useEffect(() => {
    if (!account) {
      return;
    }
  
    const token = localStorage.getItem(STORAGE_KEYS.NOTIFICATIONS_TOKEN);
    setIsNotificationsSubscribed(!!account.notificationsToken && account.notificationsToken === token);
  }, [account]);

  useEffect(() => {
    const checkNotificationsSupport = async () => {
      const isMessagingSupported = await isSupported()
      if (('Notification' in window) && isMessagingSupported) {
        setNotificationsAllowed(true);
      }
    }
    checkNotificationsSupport();
  }, []);

  const handleNotificationsSubscription = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!user) {
      return;
    }

    console.log(`handleNotificationsSubscription trigger by ${user?.displayName}`);
  
    setIsUpdatingNotification(true);

    const isSubscribed = e.target.checked;

    console.log(`isSubscribed ${isSubscribed}`);

    try {
      let token: string = "";
      if (isSubscribed) {
        token = await getNotificationsToken() || "";
      } else {
        turnOffNotifications();
      }
      console.info(`handleNotificationsSubscription received token: ${token}`);
      await setDoc(doc(getFirestore(), "accounts", user.email!), {
        notificationsToken: token
      }, { merge: true });
      setIsNotificationsSubscribed(!!token);
    } catch (error) {
      console.error(`setDoc catch with error:`, error);
    }
    setIsUpdatingNotification(false);
  }

  if (!isFeatureEnable(FEATURE_FLAGS.NOTIFICATIONS, user?.email)) {
    return;
  }

  const isDisabled = isUpdatingNotification || !notificationsAllowed;
  const input = (
    <div>
      <Form.Check
        className="me-2"
        type="switch"
        label="Receive notifications on this device"
        id="notifications"
        disabled={isDisabled}
        checked={isNotificationsSubscribed}
        onChange={handleNotificationsSubscription}
        inline
      />
      <OverlayTrigger
        overlay={(
          <Popover>
            <Popover.Body>
              <p className="mb-1">You'll receive a notification when:</p>
              <ul className="m-0">
                <li>You were on a waiting list but someone left and you got it</li>
                <li>You've been removed from a game</li>
              </ul>
            </Popover.Body>
          </Popover>
        )}
      >
        <i className="bi bi-info-circle-fill text-primary"></i>
      </OverlayTrigger>
    </div>
  )

  const renderAlerts = () => {
    if (notificationsAllowed) {
      return;
    }

    if (isIosSafari()) {
      return (
        <Alert variant="warning" className="d-inline-flex flex-row align-items-center px-2 py-2">
          <i className="bi bi-info-circle-fill me-2"></i>
          <div className="d-inline-flex flex-row align-items-center flex-wrap ms-1">
            <span>Safari only allows phone notifications if you&nbsp;</span>
            <Button variant="link" className="p-0" onClick={() => setShowSafariWizard(true)}>add it to the home screen</Button>.
          </div>
        </Alert>
      )
    }

    if (isIos()) {
      return (
        <Alert variant="info" className="d-inline-flex flex-row align-items-center px-2 py-2">
          <i className="bi bi-info-circle-fill me-2 text-primary"></i>
          <div className="d-inline-flex flex-row align-items-center flex-wrap ms-1">
            <span>This browser doesn't support notifications.</span>
            <p className="d-inline-flex flex-row align-items-center flex-wrap m-0">
              <span>Safari does when you&nbsp;</span>
              <Button variant="link" className="p-0" onClick={() => setShowSafariWizard(true)}>add the website to the home screen</Button>.
            </p>
          </div>
        </Alert>
      )
    }

    return (
      <Alert variant="info" className="d-inline-flex flex-row align-items-center px-2 py-2">
        <i className="bi bi-info-circle-fill me-2 text-primary"></i>
        <div className="d-inline-flex flex-row align-items-center flex-wrap ms-1">
          <span>This browser doesn't support notifications</span>
        </div>
      </Alert>
    )
  }

  return (
    <div className={cn({ [`${className}`]: !!className })}>
      {renderAlerts()}

      {input}

      <div className="d-inline-flex flex-row align-items-center p-0 mt-3 mb-0">
        <div className="flex-row align-items-center flex-wrap ms-1">
          <p className="mb-0 text-secondary-emphasis">Notifications will only be sent to <em>one</em> device</p>
        </div>
      </div>

      {!notificationsAllowed && (
        <Modal centered onHide={() => setShowSafariWizard(false)} show={showSafariWizard}>
          <Modal.Header closeButton>
            <Modal.Title className="h5">Add UP Bookings to your Home Screen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ol>
              <li>In Safari tap <em>Share</em> <i className="bi bi-box-arrow-up"></i> in the menu bar</li>
              <li>Scroll down the list of options, then tap <em>Add to Home Screen</em></li>
              <li>Look for the new UP Bookings icon in your phone's home screen and open it</li>
              <li>After you Login you'll be able to enable notifications</li>
            </ol>

            <p className="mt-5 mb-2">If you don’t see the <em>Add to Home Screen</em> option, you can add it like this:</p>
            <ol>
              <li>Scroll down to the bottom of the list</li>
              <li>Tap Edit Actions</li>
              <li>Then tap <i className="bi bi-plus-circle"></i> Add to Home Screen</li>
            </ol>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default withUserContext(NotificationsToggle);
