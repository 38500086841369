import { FormEvent, Fragment, ReactNode, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { RANKING, RANKING_DESCRIPTIONS } from "../../../types/Account";

type FeedbackProps = {
  className?: string;
  isDisabled?: boolean;
  onSubmit?: (feedback: string[]) => void
  initialFeedback?: string[];
  note?: string|ReactNode;
}

const Feedback = ({ className, isDisabled, onSubmit, initialFeedback = [], note }: FeedbackProps) => {
  const [feedback, setFeedback] = useState<string[]>(initialFeedback);

  const onSubmitFeedback = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(feedback);
    }
  }

  const onFeedback = (key: keyof typeof RANKING) => {
    setFeedback(prev => {
        if (prev.includes(key)) {
            return prev.filter(v => v !== key);
        }
        if (prev.length < 2) {
            return prev.concat(key)
        }
        return prev.slice(1).concat(key);
    });
  }

  const renderFeedback = () => {
    const buttons = Object.keys(RANKING)
    .filter((k) => !isNaN(k as any)) // eslint-disable-line @typescript-eslint/no-explicit-any
    .map(k => {
        const value = Number(k);
        const label = RANKING[value];
        const optionIsOneStepAway = (Math.abs(Number(feedback[0]) - value)) > 1;
        const isChecked = feedback.includes(k);
        const disableInput = !onSubmit ||
            isDisabled ||
            (feedback.length === 2 && !isChecked) ||
            feedback.length === 1 && optionIsOneStepAway;

        return (
            <Fragment key={label}>
                <input
                    type="checkbox" className="btn-check"
                    id={label} autoComplete="off"
                    checked={isChecked}
                    onChange={() => onFeedback(k as keyof typeof RANKING)}
                    disabled={disableInput}
                />
                <label className="btn btn-outline-secondary" htmlFor={label}>{label}</label>
            </Fragment>
        )
    });

    return (
      <>
        <ButtonGroup className="feedback mb-2 d-block d-sm-none" aria-label="Ranking options" vertical>
          {buttons}
        </ButtonGroup>
        <ButtonGroup className="feedback mb-2 d-none d-sm-block" aria-label="Ranking options">
          {buttons}
        </ButtonGroup>
      </>
    )
  }

  const renderDescriptions = () => {
    if (!feedback.length) {
      return;
    }

    return (
      <div className="mt-3">
        {feedback
          .sort((a, b) => Number(a) - Number(b))
          .map((f) => {
            const key = Number(f);
            const copy = RANKING_DESCRIPTIONS[key as keyof typeof RANKING_DESCRIPTIONS];
            return (
              <div className="my-2" key={f}>
                <strong>{RANKING[key]}</strong>
                <p className="mt-2">{copy}</p>
              </div>
            )
          })}
      </div>
    );
  }

  if (!onSubmit) {
    return renderFeedback();
  }

  return (
    <form onSubmit={onSubmitFeedback} className={className || ""}>
      {!!note && note}
      {renderFeedback()}
      {renderDescriptions()}
      <Button variant="primary" className="mt-2" type="submit" disabled={isDisabled || feedback.length < 2}>Send</Button>
  </form>
  )
}

export default Feedback;