import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./Header/Header";
import '../../helpers/firebase';
import UserProvider from '../../contexts/user';
import Home from '../Home/Home';
import Login from '../Login/Account';
import Game from '../Game/Game';
import Create from '../Game/Create';
import Player from '../Player/Player';
import NotFound from "../NotFound/NotFound";
import PrivateRoute from './PrivateRoute';
import Community from '../Community/Community';
import { isUnitedPadelHostname } from "../../helpers/communities";
import "bootstrap-icons/font/bootstrap-icons.css";
import './App.css';
import { FEATURE_FLAGS, isFeatureEnable } from "../../helpers/featureFlags";

const App = () => {
  const isUnitedPadel = isUnitedPadelHostname();
  const isRankingEnabled = isFeatureEnable(FEATURE_FLAGS.RANKING);

  return (
    <UserProvider>
      <div className="container-md">
        <Router>
          <Header />
          <Switch>
            <PrivateRoute exact path="/" component={isUnitedPadel ? Community : Home} />
            <Route path={["/login", "/account"]} component={Login} />
            <PrivateRoute path="/game/:id/update" component={Create} />
            <PrivateRoute path="/game/new" component={Create} />
            <Route path="/game/:id" component={Game} />
            {isRankingEnabled && <Route path="/player/:id" component={Player} />}
            {!isUnitedPadel && <PrivateRoute exact path="/community/:id" component={Community} />}
            {!isUnitedPadel && <Route exact path="/community/:communityId/game/:id" component={Game} />}
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </div>
    </UserProvider>
  );
}

export default App;