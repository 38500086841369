import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserContextProps, withUserContext } from "../../../contexts/user";
import unitedPadelLogo from "../../../media/united-padel-logo.jpg";
import { isUnitedPadelHostname } from "../../../helpers/communities";
import "./Header.css"
import { getAvatarUrl } from "../../../helpers/user";

type HeaderProps = UserContextProps;

const Header = ({ userContext: { isLoading, user }}: HeaderProps) => {
  const renderAccountLink = () => {
    if (isLoading) {
      return <p className="m-0"><i className="fas fa-spinner spin"></i></p>
    }
    if (user?.photoURL) {
      return <img className="user-circle rounded-circle" src={user.photoURL} alt={user.displayName || ""} height={75} width={75} />
    }
    if (user?.displayName) {
      return <img className="user-circle rounded-circle" src={getAvatarUrl(user.displayName)} alt={user.displayName || ""} height={75} width={75} />
    }
    return <span>Login <i className="bi bi-chevron-right"></i></span>
  }

  return (
    <header>
      <Navbar expand="lg" bg="transparent">
        <Link className="navbar-brand d-flex align-items-center" to="/">
          {isUnitedPadelHostname() ? (
            <>
              <img src={unitedPadelLogo} className="brand-logo me-3" alt="United Padel New Zealand" />
              <div>
                <span className="brand-name mb-0">
                  United Padel NZ
                </span>
                <p className="subtitle text-muted mb-0">Bookings</p>
              </div>
            </>
          ) : (
            <span className="brand-name mb-0">
              Join my game
            </span>
          )}
        </Link>
        <Link className="nav-link d-block ms-auto" to="/account">
          {renderAccountLink()}
        </Link>
      </Navbar>
      <hr className="mt-0" />
    </header>
  );
}

export default withUserContext(Header);